import { MinDataAddress } from '~components/Forms/MiniAddressFrom';
import { NotificationsContactsFields } from '~components/Forms/Notifications/NotificationRow';
import { FileImg } from '~store/CreateLabelStore';

export type ID = number | string;

// Flow
export const INIT_FLOW = 'INIT_FLOW';
export const RESET_FLOW = 'RESET_FLOW';
export const ADD_ADDRESS_IN_FLOW = 'ADD_ADDRESS_IN_FLOW';

export const FILTER_CARRIER_FLOW = 'FILTER_CARRIER_FLOW';
export const SORT_CARRIER_FLOW = 'SORT_CARRIER_FLOW';
export const SET_UNIQUE_CARRIER_SERVICES_FLOW = 'SET_UNIQUE_CARRIER_SERVICES_FLOW';
export const SET_FILTERED_SERVICES = 'SET_FILTERED_SERVICES';
export const DISABLE_GLOBAL_INSURANCE_FLOW = 'DISABLE_GLOBAL_INSURANCE_FLOW';
export const ENABLE_GLOBAL_INSURANCE_FLOW = 'ENABLE_GLOBAL_INSURANCE_FLOW';
export const SHOW_GLOBAL_INSURANCE_FLOW = 'SHOW_GLOBAL_INSURANCE_FLOW';

export type Flow = 'send' | 'pickup' | 'rate' | 'cucustomer' | 'multisend' | 'create';
export type PayloadPackageRequest =
	| { quantity: number }
	| { insurance: number }
	| { type: string }
	| { height: number }
	| { width: number }
	| { length: number }
	| { weight: number }
	| { description: string }
	| { value: number }
	| { product_code: string }
	| { currency: string }
	| { unit_code: string }
	| { content: string }
	| { is_irregular_delivery: boolean }
	| { measurement: string };

export type FlowActions =
	| { type: typeof INIT_FLOW; flow: Flow }
	| { type: typeof RESET_FLOW }
	| { type: typeof ADD_ADDRESS_IN_FLOW; for: 'origin' | 'destination' | 'none' }
	| {
			type: typeof FILTER_CARRIER_FLOW;
			filter: string;
			services: ServiceResponse[];
	  }
	| { type: typeof SORT_CARRIER_FLOW; sort: number }
	| { type: typeof DISABLE_GLOBAL_INSURANCE_FLOW }
	| { type: typeof ENABLE_GLOBAL_INSURANCE_FLOW }
	| { type: typeof SHOW_GLOBAL_INSURANCE_FLOW; show: boolean }
	| { type: typeof SET_UNIQUE_CARRIER_SERVICES_FLOW; carriers: string[] }
	| { type: typeof SET_FILTERED_SERVICES; services: ServiceResponse[] };

//=================================================================================
// Send
export const RESET_SEND = 'RESET_SEND';
export const SET_BATCH_ID = 'SET_BATCH_ID';
export const SET_RATE_ID = 'SET_RATE_ID';
export const SET_FORM_PACKAGES_VALID_SEND = 'SET_FORM_PACKAGES_VALID_SEND';
export const SET_ORIGIN_ADDRESS_SEND = 'SET_ORIGIN_ADDRESS_SEND';
export const SET_DESTINATION_ADDRESS_SEND = 'SET_DESTINATION_ADDRESS_SEND';
export const SWIPE_ADDRESS_SEND = 'SWIPE_ADDRESS_SEND';
export const SET_ORIGIN_CONTACT_SEND = 'SET_ORIGIN_CONTACT_SEND';
export const SET_DESTINATION_CONTACT_SEND = 'SET_DESTINATION_CONTACT_SEND';
export const ADD_PACKAGE_SEND = 'ADD_PACKAGE_SEND';
export const REMOVE_PACKAGE_SEND = 'REMOVE_PACKAGE_SEND';
export const ADD_MERCHANDISE_PACKAGE_SEND = 'ADD_MERCHANDISE_PACKAGE_SEND';
export const SET_SERVICE_SEND = 'SET_SERVICE_SEND';
export const CAN_CONTINUE_TO_RATE_SEND = 'CAN_CONTINUE_TO_RATE_SEND';
export const CAN_CONTINUE_TO_SUMMARY_SEND = 'CAN_CONTINUE_TO_SUMMARY_SEND';
export const TRIGGER_VALIDATE_BEFORE_RATE_SEND = 'TRIGGER_VALIDATE_BEFORE_RATE_SEND';
export const UPDATE_COLUMN_PACKAGE_SEND = 'UPDATE_COLUMN_PACKAGE_SEND';
export const SET_INSURANCE_ON_SUMMARY = 'SET_INSURANCE_ON_SUMMARY';
export const SET_ADDRESS_QUERY = 'SET_ADDRESS_QUERY';
export const RESET_SERVICE_SEND = 'RESET_SERVICE_SEND';
export const SET_FORMATTED_ADDRESS = 'SET_FORMATTED_ADDRESS';
export const SET_ESTAFETA_OUTPUT_TYPE = 'SET_ESTAFETA_OUTPUT_TYPE';
export const SET_SAVE_ESTAFETA_OUTPUT_TYPE = 'SET_SAVE_ESTAFETA_OUTPUT_TYPE';
export const SET_EXTRA_INFORMATION = 'SET_EXTRA_INFORMATION';
export const SET_USE_TRADING = 'SET_USE_TRADING';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export type SendActions =
	| { type: typeof SET_USE_TRADING; value: 'prepaid' | 'regular' }
	| {
			type: typeof SET_ORIGIN_ADDRESS_SEND;
			address: AddressRequest | null;
			contacts: Contact[];
	  }
	| {
			type: typeof SET_DESTINATION_ADDRESS_SEND;
			address: AddressRequest | null;
			contacts: Contact[];
	  }
	| { type: typeof SWIPE_ADDRESS_SEND }
	| { type: typeof SET_ORIGIN_CONTACT_SEND; contact: Contact | null }
	| { type: typeof SET_BATCH_ID; batchId: string | null }
	| { type: typeof SET_RATE_ID; rateId: string | null }
	| { type: typeof SET_DESTINATION_CONTACT_SEND; contact: Contact | null }
	| { type: typeof ADD_PACKAGE_SEND; pkg: Package }
	| { type: typeof ADD_MERCHANDISE_PACKAGE_SEND; pkg_id: ID; payload: Merchandise }
	| { type: typeof REMOVE_PACKAGE_SEND; id: ID }
	| {
			type: typeof UPDATE_COLUMN_PACKAGE_SEND;
			pkg_id: ID;
			payload: PayloadPackageRequest;
			merchandiseId?: string;
	  }
	| { type: typeof SET_SERVICE_SEND; service: ServiceRequest }
	| { type: typeof CAN_CONTINUE_TO_RATE_SEND }
	| { type: typeof CAN_CONTINUE_TO_SUMMARY_SEND }
	| { type: typeof TRIGGER_VALIDATE_BEFORE_RATE_SEND; status: boolean }
	| { type: typeof RESET_SEND }
	| { type: typeof RESET_SERVICE_SEND }
	| { type: typeof SET_FORM_PACKAGES_VALID_SEND; status: boolean }
	| { type: typeof SET_INSURANCE_ON_SUMMARY; status: boolean }
	| { type: typeof SET_FORMATTED_ADDRESS; destination: any; origin: any }
	| {
			type: typeof SET_ADDRESS_QUERY;
			query: string | null;
			target: 'origin' | 'destination';
	  }
	| { type: typeof SET_ESTAFETA_OUTPUT_TYPE; outputType: string | null }
	| {
			type: typeof SET_EXTRA_INFORMATION;
			reference: string;
			additionalInfo: string;
			business?: NewBusiness;
			channel?: Channel;
	  }
	| { type: typeof SET_SAVE_ESTAFETA_OUTPUT_TYPE; save: boolean }
	| { type: typeof SET_NOTIFICATIONS; notifications: NotificationContacts };

//=================================================================================
// MultiSend
export const SET_CARRIER_MULTISEND = 'SET_CARRIER_MULTISEND';
export const SET_SERVICE_MULTISEND = 'SET_SERVICE_MULTISEND';
export const RESET_MULTISEND = 'RESET_MULTISEND';

export type MultiSendActions =
	| { type: typeof SET_CARRIER_MULTISEND; carrier: string | null }
	| { type: typeof SET_SERVICE_MULTISEND; service: string | null }
	| { type: typeof RESET_MULTISEND };

//=================================================================================
// Pickup
export const FETCH_PICKUPS = 'FETCH_PICKUPS';
export const SET_PICKUP_DETAILS = 'SET_PICKUP_DETAILS';
export const UPDATE_PICKUP_DETAILS = 'UPDATE_PICKUP_DETAILS';
export const UPDATE_PICKUP = 'UPDATE_PICKUP';
export const CANCEL_PICKUP = 'CANCEL_PICKUP';
export const SET_STATUS_PICKUP = 'SET_STATUS_PICKUP';
export const SET_REASON_PICKUP = 'SET_REASON_PICKUP';
export const SET_CARRIER_FOLIO_PICKUP = 'SET_CARRIER_FOLIO_PICKUP';
export const CAN_CONTINUE_TO_EDIT_PICKUP = 'CAN_CONTINUE_TO_EDIT_PICKUP';
export const RESET_PICKUP = 'RESET_PICKUP';
export const SET_ADDRESS_PICKUP = 'SET_ADDRESS_PICKUP';
export const SET_CONTACT_PICKUP = 'SET_CONTACT_PICKUP';
export const RESET_LABELS_PICKUP = 'RESET_LABELS_PICKUP';
export const ADD_LABEL_PICKUP = 'ADD_LABEL_PICKUP';
export const SET_LABELS_PICKUP = 'SET_LABELS_PICKUP';
export const REMOVE_LABEL_PICKUP = 'REMOVE_LABEL_PICKUP';
export const SET_INDICATIONS_PICKUP = 'SET_INDICATIONS_PICKUP';
export const SET_EXTRA_INDICATIONS_PICKUP = 'SET_EXTRA_INDICATIONS_PICKUP';
export const SET_DATE_PICKUP = 'SET_DATE_PICKUP';
export const SET_TIME_PICKUP = 'SET_TIME_PICKUP';
export const TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP = 'TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP';
export const CAN_CONTINUE_TO_SUMMARY_PICKUP = 'CAN_CONTINUE_TO_SUMMARY_PICKUP';
export const SET_CARRRIER_PICKUP = 'SET_CARRRIER_PICKUP';
export const CAN_MOVE_TO_BOOKLET_PICKUP = 'CAN_MOVE_TO_BOOKLET_PICKUP';
export const SET_INSURANCE_FOR_PICKUP = 'SET_INSURANCE_FOR_PICKUP';
export const SET_PICKUP_SUPERVISOR = 'SET_PICKUP_SUPERVISOR';
export const SET_PICKUP_NOTES = 'SET_PICKUP_NOTES';

export type PickUpActions =
	| { type: typeof RESET_PICKUP }
	| {
			type: typeof SET_ADDRESS_PICKUP;
			address: AddressRequest | null;
			contacts: Contact[];
	  }
	| { type: typeof SET_CONTACT_PICKUP; contact: Contact | null }
	| { type: typeof ADD_LABEL_PICKUP; label: ShipmentSendResponse }
	| { type: typeof SET_LABELS_PICKUP; labels: ShipmentSendResponse[] }
	| { type: typeof REMOVE_LABEL_PICKUP; id: ID }
	| { type: typeof SET_STATUS_PICKUP; status: string }
	| { type: typeof SET_REASON_PICKUP; reason: string }
	| { type: typeof SET_CARRIER_FOLIO_PICKUP; carrierFolio: string }
	| { type: typeof SET_PICKUP_SUPERVISOR; pickupSupervisor: string }
	| { type: typeof SET_PICKUP_NOTES; pickupNotes: string }
	| { type: typeof CAN_CONTINUE_TO_EDIT_PICKUP; isAgent: boolean }
	| { type: typeof SET_INDICATIONS_PICKUP; indications: string }
	| { type: typeof SET_EXTRA_INDICATIONS_PICKUP; extra_indications: string }
	| { type: typeof SET_DATE_PICKUP; date: Date | null }
	| { type: typeof SET_TIME_PICKUP; time: Date | null; target: 'start' | 'end' }
	| { type: typeof TRIGGER_VALIDATE_BEFORE_SUMMARY_PICKUP; status: boolean }
	| { type: typeof CAN_CONTINUE_TO_SUMMARY_PICKUP }
	| { type: typeof RESET_LABELS_PICKUP }
	| { type: typeof SET_CARRRIER_PICKUP; carrier: string }
	| { type: typeof FETCH_PICKUPS; pickups: ShipmentPickUpResponse[] }
	| { type: typeof CANCEL_PICKUP; id: ID }
	| { type: typeof UPDATE_PICKUP; pickup: ShipmentPickUpResponse }
	| { type: typeof CAN_MOVE_TO_BOOKLET_PICKUP; value: boolean }
	| { type: typeof SET_PICKUP_DETAILS; id: ID; details: PickUpLabelDetails }
	| {
			type: typeof UPDATE_PICKUP_DETAILS;
			id: ID;
			labels: ShipmentSendResponse[];
	  }
	| {
			type: typeof SET_INSURANCE_FOR_PICKUP;
			insuranceForOrder: boolean;
	  };

//==============================================================================
// Rate
export const SET_DESTINATION_ADDRESS_RATE = 'SET_DESTINATION_ADDRESS_RATE';
export const SET_ORIGIN_ADDRESS_RATE = 'SET_ORIGIN_ADDRESS_RATE';
export const RESET_RATE = 'RESET_RATE';
export const ADD_PACKAGE_RATE = 'ADD_PACKAGE_RATE';
export const REMOVE_PACKAGE_RATE = 'REMOVE_PACKAGE_RATE';
export const UPDATE_COLUMN_PACKAGE_RATE = 'UPDATE_COLUMN_PACKAGE_RATE';
export const TRIGGER_VALIDATE_BEFORE_RATE_RATE = 'TRIGGER_VALIDATE_BEFORE_RATE_RATE';
export const CAN_CONTINUE_TO_RATE_RATE = 'CAN_CONTINUE_TO_RATE_RATE';
export const SET_FORM_PACKAGES_VALID_RATE = 'SET_FORM_PACKAGES_VALID_RATE';
export const TOGGLE_ORIGIN_ADDRESS_COMPONENT = 'TOGGLE_ORIGIN_ADDRESS_COMPONENT';
export const TOGGLE_DESTINATION_ADDRESS_COMPONENT = 'TOGGLE_DESTINATION_ADDRESS_COMPONENT';

export type RateActions =
	| { type: typeof ADD_PACKAGE_RATE; pkg: Package }
	| { type: typeof REMOVE_PACKAGE_RATE; id: ID }
	| {
			type: typeof UPDATE_COLUMN_PACKAGE_RATE;
			id: ID;
			payload: PayloadPackageRequest;
	  }
	| {
			type: typeof SET_DESTINATION_ADDRESS_RATE;
			address: AddressRequest | null;
	  }
	| { type: typeof TOGGLE_DESTINATION_ADDRESS_COMPONENT }
	| { type: typeof TOGGLE_ORIGIN_ADDRESS_COMPONENT }
	| { type: typeof SET_ORIGIN_ADDRESS_RATE; address: AddressRequest | null }
	| { type: typeof RESET_RATE }
	| { type: typeof TRIGGER_VALIDATE_BEFORE_RATE_RATE; status: boolean }
	| { type: typeof CAN_CONTINUE_TO_RATE_RATE }
	| { type: typeof SET_FORM_PACKAGES_VALID_RATE; status: boolean };

//==============================================================================
// Create
export const SET_DESTINATION_ADDRESS_CREATE = 'SET_DESTINATION_ADDRESS_CREATE';
export const SET_ORIGIN_ADDRESS_CREATE = 'SET_ORIGIN_ADDRESS_CREATE';
export const RESET_CREATE = 'RESET_CREATE';
export const ADD_PACKAGE_CREATE = 'ADD_PACKAGE_CREATE';
export const REMOVE_PACKAGE_CREATE = 'REMOVE_PACKAGE_CREATE';
export const UPDATE_COLUMN_PACKAGE_CREATE = 'UPDATE_COLUMN_PACKAGECREATEE';
export const TRIGGER_VALIDATE_BEFORE_CREATE_CREATE = 'TRIGGER_VALIDATE_BEFORE_CREATE_CREATE';
export const CAN_CONTINUE_TO_CREATE_CREATE = 'CAN_CONTINUE_TO_CREATE_CREATE';
export const SET_FORM_PACKAGES_VALID_CREATE = 'SET_FORM_PACKAGES_VALID_CREATE';
export const SET_ORIGIN_CONTACT_CREATE = 'SET_ORIGIN_CONTACT_CREATE';
export const SET_DESTINATION_CONTACT_CREATE = 'SET_DESTINATION_CONTACT_CREATE';
export const CAN_CONTINUE_TO_RATE_CREATE = 'CAN_CONTINUE_TO_RATE_CREATE';

export type CreateActions =
	| { type: typeof ADD_PACKAGE_CREATE; pkg: Package }
	| { type: typeof REMOVE_PACKAGE_CREATE; id: ID }
	| {
			type: typeof UPDATE_COLUMN_PACKAGE_CREATE;
			id: ID;
			payload: PayloadPackageRequest;
	  }
	| { type: typeof TOGGLE_DESTINATION_ADDRESS_COMPONENT }
	| { type: typeof TOGGLE_ORIGIN_ADDRESS_COMPONENT }
	| {
			type: typeof SET_ORIGIN_ADDRESS_CREATE;
			address: AddressRequest | null;
			contacts: Contact[];
	  }
	| {
			type: typeof SET_DESTINATION_ADDRESS_CREATE;
			address: AddressRequest | null;
			contacts: Contact[];
	  }
	| { type: typeof RESET_CREATE }
	| { type: typeof CAN_CONTINUE_TO_RATE_SEND }
	| { type: typeof TRIGGER_VALIDATE_BEFORE_CREATE_CREATE; status: boolean }
	| { type: typeof CAN_CONTINUE_TO_CREATE_CREATE }
	| { type: typeof SET_FORM_PACKAGES_VALID_CREATE; status: boolean };

//==============================================================================
// Consolidated

export const RESET_CONSOLIDATED = 'RESET_CONSOLIDATED';
export const CANCEL_CONSOLIDATED = 'CANCEL_CONSOLIDATED';
export const START_CONSOLIDATED = 'START_CONSOLIDATED';
export const SET_CARRIERS_CONSOLIDATED = 'SET_CARRIERS_CONSOLIDATED';
export const SET_CONSOLIDATED_ORIGIN_FORM = 'SET_CONSOLIDATED_ORIGIN_FORM';
export const SET_CONSOLIDATED_DESTINATION_FORM = 'SET_CONSOLIDATED_DESTINATION_FORM';
export const SET_CONSOLIDATED_SPECIALS = 'SET_CONSOLIDATED_SPECIALS';
export const TRIGGER_VALIDATE_BEFORE_CONSOLIDATED = 'TRIGGER_VALIDATE_BEFORE_CONSOLIDATED';
export const SET_OPTION_CONSOLIDATED = 'SET_OPTION_CONSOLIDATED';
//export const POST_CONSOLIDATE = 'POST_CONSOLIDATE';
export const SET_PICK_UP_CONSOLIDATED = 'SET_PICK_UP_CONSOLIDATED';
export const SET_DELIVER_CONSOLIDATED = 'SET_DELIVER_CONSOLIDATED';

export type ConsolidatedActions =
	| { type: typeof RESET_CONSOLIDATED }
	| { type: typeof START_CONSOLIDATED }
	| { type: typeof CANCEL_CONSOLIDATED }
	| { type: typeof SET_CARRIERS_CONSOLIDATED; carriers: string[] }
	| { type: typeof SET_CONSOLIDATED_ORIGIN_FORM; form: any }
	| { type: typeof SET_CONSOLIDATED_DESTINATION_FORM; form: any }
	| { type: typeof SET_CONSOLIDATED_SPECIALS; form: any }
	| { type: typeof TRIGGER_VALIDATE_BEFORE_CONSOLIDATED }
	| { type: typeof SET_OPTION_CONSOLIDATED; option: ID }
	| { type: typeof SET_PICK_UP_CONSOLIDATED; status: boolean }
	| { type: typeof SET_DELIVER_CONSOLIDATED; status: boolean };
//| { type: typeof POST_CONSOLIDATE };

//==============================================================================
// Data
export const FETCH_ADDRESSES_DATA = 'FETCH_ADDRESSES_DATA';
export const FETCH_PACKAGES_DATA = 'FETCH_PACKAGES_DATA';

export const ADD_ADDRESS_DATA = 'ADD_ADDRESS_DATA';
export const ADD_PACKAGE_TEMP_DATA = 'ADD_PACKAGE_TEMP_DATA';
export const EDIT_PACKAGE_TEMP_DATA = 'EDIT_PACKAGE_TEMP_DATA';
export const CLEAR_ADDRESS_DATA = 'CLEAR_ADDRESS_DATA';

export const REMOVE_PACKAGE = 'REMOVE_PACKAGE';

export const FETCH_SUCCESS_DATA = 'FETCH_SUCCESS_DATA';
export const FETCH_INIT_DATA = 'FETCH_INIT_DATA';
export const FETCH_FAIL_DATA = 'FETCH_FAIL_DATA';
export const CLEAR_ERROR_FETCH_DATA = 'CLEAR_ERROR_FETCH_DATA';

export const POST_RATE_DATA = 'POST_RATE_DATA';
export const POST_GUIDE_DATA = 'POST_GUIDE_DATA';
export const POST_PICKUP_DATA = 'POST_PICKUP_DATA';
export const CLEAR_SERVICES_DATA = 'CLEAR_SERVICES_DATA';
export const CLEAR_PARCEL_NUMBERS = 'CLEAR_PARCEL_NUMBERS';

export const FETCH_BUSINESS_DATA = 'FETCH_BUSINESS_DATA';

export const SET_CARRIERS = 'SET_CARRIERS';

export type CreatedLabel = {
	pdf: string;
	parcelNumber: string;
};

export type DataActions =
	| { type: typeof FETCH_ADDRESSES_DATA; addresses: NewAddress[] }
	| { type: typeof FETCH_PACKAGES_DATA; packages: Package[] }
	| { type: typeof ADD_ADDRESS_DATA; address: NewAddress }
	| { type: typeof ADD_PACKAGE_TEMP_DATA; package: Package }
	| { type: typeof CLEAR_ADDRESS_DATA }
	| { type: typeof EDIT_PACKAGE_TEMP_DATA; package: Package }
	| { type: typeof FETCH_SUCCESS_DATA }
	| { type: typeof CLEAR_PARCEL_NUMBERS }
	| { type: typeof FETCH_INIT_DATA }
	| { type: typeof FETCH_FAIL_DATA; message: string }
	| { type: typeof CLEAR_ERROR_FETCH_DATA }
	| { type: typeof POST_RATE_DATA; services: ServiceResponse[] }
	| { type: typeof POST_GUIDE_DATA; labels: Array<ShipmentSendResponse>; total: number }
	| { type: typeof POST_PICKUP_DATA; response: string }
	| { type: typeof CLEAR_SERVICES_DATA }
	| { type: typeof REMOVE_PACKAGE; id: ID }
	| { type: typeof FETCH_BUSINESS_DATA; business: NewBusiness[] }
	| { type: typeof SET_CARRIERS; carriers: CarrierList };

// ===============================================================
// CreateOrUpdateCustomerActions

// export const SET_GENERAL_INFO_DRAFT_CUCUSTOMER = 'SET_GENERAL_INFO_DRAFT_CUCUSTOMER';
// export const SET_GENERAL_INFO_VALID_CUCUSTOMER = 'SET_GENERAL_INFO_VALID_CUCUSTOMER';
// export const SET_ADDRESS_INFO_DRAFT_CUCUSTOMER = 'SET_ADDRESS_INFO_DRAFT_CUCUSTOMER';
// export const SET_TOUCHED_ADDRESS_CUCUSTOMER = 'SET_TOUCHED_ADDRESS_CUCUSTOMER';
// export const SET_LEGAL_INFO_DRAFT_CUCUSTOMER = 'SET_LEGAL_INFO_DRAFT_CUCUSTOMER';
// export const SET_EDITING_ADDRESS_CUCUSTOMER = 'SET_EDITING_ADDRESS_CUCUSTOMER';
// export const SET_LEGAL_INFO_VALID_CUCUSTOMER = 'SET_LEGAL_INFO_VALID_CUCUSTOMER';
export const SET_SERVICES_CUCUSTOMER = 'SET_SERVICES_CUCUSTOMER';
// export const RESET_CUCUSTOMER = 'RESET_CUCUSTOMER';

export type CreateOrUpdateCustomerActions =
	// 	| { type: typeof SET_GENERAL_INFO_DRAFT_CUCUSTOMER; form: any }
	// 	| { type: typeof SET_GENERAL_INFO_VALID_CUCUSTOMER; valid: boolean }
	// 	| { type: typeof SET_ADDRESS_INFO_DRAFT_CUCUSTOMER; address: NewAddress }
	// 	| { type: typeof SET_TOUCHED_ADDRESS_CUCUSTOMER; touched: boolean }
	// 	| { type: typeof SET_LEGAL_INFO_DRAFT_CUCUSTOMER; form: any }
	// 	| { type: typeof SET_LEGAL_INFO_VALID_CUCUSTOMER; valid: boolean }
	{ type: typeof SET_SERVICES_CUCUSTOMER; services: BookletServiceForm[] };
// 	| { type: typeof SET_EDITING_ADDRESS_CUCUSTOMER; idCustomer: number; idUser: number }
// 	| { type: typeof RESET_CUCUSTOMER };

//================================================================
// Simulated UserForm Actions

export const SET_SIMULATED_USER = 'SET_SIMULATED_USER';
export const SET_SIMULATED_CUSTOMER_SIMULATED_USER = 'SET_SIMULATED_CUSTOMER_SIMULATED_USER';
export const RESET_SIMULATED_USER = 'RESET_SIMULATED_USER';
export const TRIGGER_ERRORS_SIMULATED_USER = 'TRIGGER_ERRORS_SIMULATED_USER';
export const CLEAR_ERROR_SIMULATED_USER = 'CLEAR_ERROR_SIMULATED_USER';

export type SimulatedUserActions =
	| { type: typeof SET_SIMULATED_USER; user: UserDetails | null }
	| {
			type: typeof SET_SIMULATED_CUSTOMER_SIMULATED_USER;
			customer: CustomerDetails | null;
	  }
	| { type: typeof TRIGGER_ERRORS_SIMULATED_USER }
	| { type: typeof CLEAR_ERROR_SIMULATED_USER }
	| { type: typeof RESET_SIMULATED_USER };

//================================================================

/**
 * El interno
 */
export type ServiceRequest = {
	carrier: string;
	service_description: string;
	service_id: string;
	//TODO add badges
	insurance: number;
	cost: number;
	rate_id: number;
	can_service_be_traded: boolean | undefined;
	reissue: string;
	label_price?: number;
	overweight_price?: number;
	reissue_price?: number;
	irregular_delivery_price?: number;
	tag_irregular_delivery?: string;
	office_list?: ServiceOffice[];
};

/**
 * El interno
 */
export type Contact = {
	id?: ID;
	name: string;
	phone_number: string;
	type_phone_number?: string;
	extension?: string | null;
	email: string;
	type: string;
	notification_info?: NotificationsContactsFields | null;
};

export type AddressRequest = {
	id: ID;
	alias?: string;
	type?: string;
	number: string;
	suite_number: string;
	coordinates: string | { lat: number; lng: number };
	city: string;
	country: string;
	reference: string;
	reference_streets: string;
	street: string;
	street1?: string;
	street2?: string;
	street3?: string;
	address?: string;
	business_name: string;
	neighborhood: string;
	state: string;
	zip_code: string;
	contact: Contact | null;
	tax_id: string;
	latitude?: number;
	longitude?: number;
	contacts?: Contact[];
	ocurre_office?: string;
};

export type NewAddress = {
	id: ID; //when fetch
	user_id?: number;
	address?: string; //when fetch
	customer?: { id: number; name: string };
	alias: string;
	scope: 'user' | 'group' | 'global' | 'Personal'; // Personal works when is temp
	coordinates: string;
	street: string;
	number: string | null;
	suite_number?: string | null;
	city: string;
	business_name: string;
	neighborhood: string;
	state: string;
	zip_code: string;
	country: string;
	reference?: string | null;
	reference_streets?: string | null;
	contacts: Contact[];
	tax_id: string;
	address_type?: string;
};

export enum BusinessStatus {
	ACTIVE = 'active',
	MAIN = 'main',
	DISABLED = 'disabled'
}

export type NewBusiness = {
	id?: number;
	customer_id?: number;
	tax_id: string;
	business_name: string;
	company_name: string;
	fiscal_address: {
		neighborhood: string;
		city: string;
		state: string;
		number: string;
		suite_number?: string;
		street: string;
		zip_code: string;
	};
	tax_regimen: string;
	payment_type: string;
	payment_method: string;
	cfdi_use: string;
	users: User[];
	groups: Group[];
	can_edit_tax_id: boolean;
	status: BusinessStatus;
	notification_emails: { name: string; email: string }[];
	conditions: PaymentConditions;
	company_name_capital?: string;
	capital_regime?: string;
	created_at: string;
	order?: string | null;
	change_by?: number;
};

export type Business = {
	id: number;
	created_at: string;
	business_name: string;
	company_name: string;
	groups: string | Group[];
	users: User[];
	status: BusinessStatus;
};

export type CustomerInfo = {
	id: number;
	name: string;
};

export type Address = NewAddress & {
	owner: {
		id: number;
		name: string;
	} | null;
	customer: CustomerInfo;
};

export type ZipCode = {
	code: string;
	neighborhood: string;
	locality: string;
	state: string;
	municipality: string;
	country: string;
};

export type Package = {
	id: ID;
	pkg_id: ID;
	alias: string;
	type: string;
	content: string;
	height: number;
	width: number;
	length: number;
	weight: number;
	measurement: 'cm' | 'm';
	//Only when request
	quantity?: number;
	insurance?: number; // TODO check if this is still valid
	customer?: CustomerInfo;
	customer_id?: number;
	merchandise?: Merchandises;
	description?: string;
	is_irregular_delivery?: boolean;
};

export type Group = {
	id: ID;
	name: string;
	description?: string;
	services: BookletServiceForm[];
	users: User[];
	is_business_default?: boolean;
	default_business_id?: number;
};

export type User = {
	id: ID;
	name: string;
	last_name: string;
	email: string;
	phone_number: string;
	extension?: string;
	permissions: string;
	customer?: { id: number | undefined };
	is_business_default?: boolean;
	notifications?: UserNotification;
};

export type UserNotification = {
	modality: string;
	status: string;
	is_enable: boolean;
	whatsapp_number: string;
	alias_name?: string;
	alias_name_label?: string;
};

export type Label = {
	// del backend
	id: ID;
	created_at: string;
	parcel_number: string;
	label: string;
	service_description: string;
	service_id: string;
	carrier: string;
};

export enum ServiceTag {
	RESHIPMENT = 'Reexpedición',
	OCCURS = 'Ocurre forzoso',
	IRREGULAR = 'E. Irregular',
	SPECIAL = 'Manejo E.',
	OVERWEIGHT = 'Sobrepeso',
	PLUS_ZONE = 'Zona Plus',
	COVERAGE_WITH_COST = 'Cobertura Con Costo',
	EXTENDED_ZONE = 'Zona Extendida'
}

/**
 * Respuesta de la API
 */
export type ServiceResponse = {
	carrier: string;
	carriers?: string[]; // only when is for consolidated
	estimated_delivery_date: string;
	service_description: string;
	service_id: string;
	total_cost: number;
	estimated_hour: number | null;
	insurance?: number;
	insurance_percentage?: number;
	rate_id: number;
	tags?: { concept: ServiceTag | string; value: number }[];
	can_service_be_traded?: { can_be_traded: boolean; service_id: string };
	reissue: string;
	prices?: Array<{ service_price: number; insurance_price?: number; rate_insurance?: number }>;
	weight: number;
	remaining_stock?: number;
	reason_disable?: string;
	office_list?: ServiceOffice[];
};

export type ServiceOffice = {
	code: string;
	name: string;
	zip_code: string;
	neighborhood: string;
	city: string;
	state: string;
	street: string;
	number: string;
	country: string;
	latitude: string;
	longitude: string;
};

// General App
// TODO check where we can put most of this actions that does not belong here
export const SET_MAPS_COORDINATES = 'SET_MAPS_COORDINATES';
export const SET_DESTINATION_COORDINATES = 'SET_DESTINATION_COORDINATES';
export const WILL_EDIT_PACKAGE = 'WILL_EDIT_PACKAGE';
export const RESET_PACKAGE_TO_EDIT = 'RESET_PACKAGE_TO_EDIT';
export const SET_GOOGLE_MAPS_SCRIPT = 'SET_GOOGLE_MAPS_SCRIPT';
export const SET_GOOGLE_MAP = 'SET_GOOGLE_MAP';
export const SET_GOOGLE_MAPS_MARKER = 'SET_GOOGLE_MAPS_MARKER';
export const SET_GOOGLE_MAPS_DESTINATION_MARKER = 'SET_GOOGLE_MAPS_DESTINATION_MARKER';
export const SET_GOOGLE_MAPS_POLYLINE = 'SET_GOOGLE_MAPS_POLYLINE';

export type AppActions =
	| {
			type: typeof SET_MAPS_COORDINATES;
			coordinates: null | { lat: number; lng: number };
	  }
	| {
			type: typeof SET_DESTINATION_COORDINATES;
			coordinates: null | { lat: number; lng: number };
	  }
	| { type: typeof WILL_EDIT_PACKAGE; pkg: Package }
	| { type: typeof RESET_PACKAGE_TO_EDIT }
	| { type: typeof SET_GOOGLE_MAPS_SCRIPT; status: boolean }
	| { type: typeof SET_GOOGLE_MAP; map: google.maps.Map<HTMLDivElement> | null }
	| {
			type: typeof SET_GOOGLE_MAPS_DESTINATION_MARKER;
			marker: google.maps.Marker | null;
	  }
	| { type: typeof SET_GOOGLE_MAPS_POLYLINE; path: google.maps.Polyline | null }
	| { type: typeof SET_GOOGLE_MAPS_MARKER; marker: google.maps.Marker | null };

//=======================================================
export type ShipmentRateResponse = {
	id: ID;
	folio: string;
	carrier: string;
	service: string;
	created_at: string;
	origin: AddressRequest;
	destination: AddressRequest;
	status: 'Cancelada' | 'En proceso' | 'Cotización Lista';
	actions?: null;
};

export type EventType = {
	createdAt: string;
	id: string | number;
	event: number | string;
	description?: string;
};

export enum ShipmentSourceType {
	API = 'ENCAMINOAPI',
	MANUAL = 'ENCAMINOMANUAL'
}

export type ShipmentSendResponse = {
	id: ID;
	carrier: string;
	service: string;
	service_description: string;
	follow_up: string;
	label?: string;
	created_at: string;
	parcel_number: string;
	origin: AddressRequest;
	destination: AddressRequest;
	status: string;
	events?: EventType[];
	actions?: null;
	packages: any[];
	user?: { id: number; name: string };
	quantity?: string;
	type?: string;
	content?: string;
	tracking_number?: string;
	service_id?: number;
	additional_info?: string;
	price?: number;
	reference?: string;
	pdf?: string;
	business_id?: number;
	files?: FileImg[];
	can_edit_business?: boolean;
	notification_contacts?: NotificationContacts;
	compensation?: ShipmentCompensation;
	modality?: ShipmentModality;
	notes?: string;
	billing?: BillingSchema;
	immediate_billing?: boolean;
	last_update?: string;
	notified?: boolean;
	reused?: boolean;
	notified_reused?: boolean;
	source?: ShipmentSourceType;
	sign_delivery?: string;
	received_by_name?: string;
};

export enum NotificationType {
	SHIPPPER = 'shipper',
	RECIPIENT = 'recipient'
}

export type NotificationContacts = [
	{
		user_id: number;
		alias: string;
		phone_number: string;
		guide_status: string;
		shipping_mode: string;
		active: boolean;
		type?: NotificationType;
	},
	{
		contact_id: number;
		alias: string;
		phone_number: string;
		guide_status: string;
		shipping_mode: string;
		active: boolean;
		type?: NotificationType;
	}
];

export enum ShipmentModality {
	PAYMENT = 'Cobro',
	INCIDENT = 'Incidente',
	EXCHANGE = 'Intercambio',
	PREPAID = 'Prepago'
}

export enum ShipmentCompensation {
	LABEL = 'Guía',
	LABEL_MERCHANDISE = 'Guía / Mercancía',
	MERCHANDISE = 'Mercancía'
}

export type ManualLabelResponse = {
	id: ID;
	carrier: string;
	label?: string;
	created_at: string;
	origin_info: AddressRequest;
	destination_info: AddressRequest;
	labels: ManualLabelItem[];
	user_id: number;
	type?: string;
	content?: string;
	additional_info?: string;
	reference: string;
	services: ServiceRowData[];
	files: { path: string; type: string; url: string }[];
	can_edit_services?: boolean;
};

export interface ServiceRowData {
	quantity: number;
	service_id: number;
	price?: number;
	title: string;
	type: string;
	declared_value: number;
}

export type ManualLabelItem = {
	tracking_number: string;
	parcel_number: string;
	status: string;
	id?: number;
	items: { type: string; content?: string; quantity: number }[];
};

export type PickupEvent = {
	status: string;
	createdAt: string;
	carrierFolio?: string;
	reason?: string;
};

export type EventTracking = {
	status: string;
	wsStatusSp: string;
	dateTime: string;
	reasonCode?: {
		code: string;
		descriptionSp: string;
	};
	pickupsItems?: number;
	folio?: string;
};

export type PickupEventTracking = {
	[parcelNumber: string]: EventTracking[];
};

export type ShipmentPickUpResponse = {
	id: ID;
	folio: string;
	carrier: string;
	service: string;
	created_at: string;
	start_time: string;
	end_time: string;
	time: string;
	origin: AddressRequest;
	date: string;
	status: string;
	// parcelNumbers
	labels: string[];
	details: PickUpLabelDetails;
	// @@@ Remove this field
	extra_indications?: string;
	events: PickupEvent[];
	events_tracking: PickupEventTracking;
	actions?: null;
	carrier_folio?: string;
	reason?: string;
	indications?: string;
	collect_in?: string;
	supervisor?: string;
	notes?: string;
	owner?: {
		id: number;
		name: string;
		customer: { id: number; name: string };
		email?: string;
	};
};

export type ShipmentPickUpDetailResponse = {
	id: ID;
	folio: string;
	carrier: string;
	service: string;
	created_at: string;
	start_time: string;
	end_time: string;
	time: string;
	origin: AddressRequest;
	date: string;
	status: string;
	labels: ShipmentSendResponse[];
	details: PickUpLabelDetails;
	extra_indications?: string;
	events: PickupEvent[];
	events_tracking: PickupEventTracking;
	actions?: null;
	carrier_folio?: string;
	reason?: string;
	indications?: string;
	collect_in?: string;
	supervisor?: string;
	notes?: string;
	owner?: {
		id: number;
		name: string;
		customer: { id: number; name: string };
		email?: string;
	};
	validated?: boolean;
};

export type PickUpLabelDetails = {
	[parcelNumber: string]: {
		origin: AddressRequest;
		destination: AddressRequest;
		packages: Package[];
	};
};

export type FedexAvailibityType = {
	schedule_day: string;
	earliest_times: string[];
	latest_times: string[];
	pickup_date: string;
};

export type ShipmentClarificationResponse = {
	id: ID;
	carrier: string;
	service: string;
	created_at: string;
	asociated_parcel_number: string;
	folio: string;
	reason: string;
	status: 'Cancelada' | 'En proceso' | 'Cotización Lista';
	actions?: null;
};

export type BookletAddressResponse = NewAddress[];
export type BookletPackageResponse = Package[];

export type BookletUserResponse = {
	id: number;
	email: string;
	name: string;
	group: { id: number; name: string } | null;
	phone_number: string;
	extension: number;
	customer?: CustomerInfo;
};
export type BookletGroupResponse = {
	id: number;
	name: string;
	description: string;
	permissions?: string;
	services?: number[];
	users?: object[];
	customer?: CustomerInfo;
};

export type BookletUserForm = {
	id: number;
	email: string;
	name: string;
	phone_number: string;
	extension?: number;
	actions?: null;
};

export type BookletServiceForm = {
	carrier: string;
	name: string;
	id: ID;
	mode: string;
	actions?: null;
	// when manage clients
	discount?: number;
	price_list?: number;
	min_stock?: number;
	enabled?: boolean;
	irregular_delivery_price?: number;
	reissue_price?: number;
	insurance_percentage?: number;
	service?: string;
	rates_service_description?: string;
};

export type Client = {
	id: ID;
	tax_id: string;
	business_name: string;
	phone_number: string | null;
	webpage: string | null;
	company_type: 'micro' | 'pequeña' | 'mediana' | 'grande';
	services: {
		enabled: boolean;
		discount: number;
		price_list: number;
	}[];
	users: UserResponse[];
};

export type Agent = {
	id: number;
	email: string;
	name: string;
	last_name: string;
	phone_number: string;
	extension: string;
	permissions: string;
	customer_id: number | null;
	customers: number[];
	role: string;
};

export type Regimen = {
	id: number;
	code: string;
	description: string;
	person: boolean;
	company: boolean;
};

export type Payment = {
	code: string;
	description: string;
};

export type CapitalRegime = {
	code: string;
	description: string;
};

export type CFDI = {
	code: string;
	description: string;
	person: boolean;
	company: boolean;
};

export type UserResponse = {
	id: ID;
	email: string;
	name: string;
	phone_number: string;
	permissions: string;
	default_address: string | null;
	group_id: string | null;
};

export enum UserRole {
	CUSTOMER = 'customer',
	ROOT = 'root',
	AGENT = 'operator',
	CLIENT = 'client',
	SELLER = 'seller',
	BILLER = 'billing'
}

export type CustomerCarriersListType = { label: string; value: string }[];

export enum CustomerModality {
	// Customer has balance or advance payment (show balance tab only)
	BALANCE = 'balance',
	// Customer has prepaid services (show stock tab only)
	PREPAID = 'prepaid',
	// Customer has balance and prepaid services (show both tabs)
	MIXED = 'mixed',
	// Customer has not balance and prepaid services (show none tab)
	CREDIT = 'credit'
}

export type UserDetails = {
	id: number;
	customer: {
		id: number;
		name: string;
		customer_modality?: CustomerModality;
		carriers: CustomerCarriersListType;
		dispersion_type?: CustomerDispersionType;
		review_reports?: CustomerReportsReview;
	} | null;
	default_address: null | string;
	email: string;
	group: null | number;
	name: string;
	options: {
		rate_price: boolean;
		theme: boolean;
		estafeta_output_type?: string;
	};
	permissions: string;
	phone_number: null | string;
	role: UserRole;
	services: {
		carrier: string;
		id: number;
		mode: string;
		service: string;
		type: string;
	}[];
	notification_info?: NotificationsContactsFields | null;
	last_name?: string;
	first_name?: string;
};

// TODO change to Response or Details, be consisten on names
export type SellerResponse = {
	id: number;
	name: string;
	email?: string;
	// customer_id: number;
	// customers: any[]; // TODO check the info
	// email: string;
	// extension: string | null;
	// options: { theme: boolean; rate_price: boolean };
	// permissions: string;
	// phone_number: null | string;
	// role: UserRole.AGENT;
	// initials: string; // Autocalculated for the InputSearchAgentIntials
};

// REPORTS
export type ReportItem = {
	id?: number;
	type?: string;
	name?: ReportName;
	sub_type?: string;
	schema?: string;
	date?: string;
	conditions?: string;
	pre_invoice_days?: string;
	report_file_type?: string;
};

export enum ReportType {
	ONLY_LABELS = 'solo guías',
	ONLY_OVERWEIGHTS = 'solo sobrepesos',
	ONLY_INSURANCES = 'solo seguros',
	ALL = 'todos',
	LABELS_AND_OVERWEIGHTS = 'guías y sobrepesos',
	LABELS_AND_INSURANCES = 'guías y seguros',
	OVERWEIGHTS_AND_INSURANCES = 'sobrepesos y seguros'
}

export enum SchemaType {
	IMMEDIATE = 'inmediato',
	WEEKLY = 'semanal',
	MONTHLY = 'mensual',
	BIWEEKLY = 'quincenal',
	INDEPENDENT = 'independiente'
}

export enum ConditionTypeReport {
	INMEDIATE = 'Inmediato',
	THREE_DAYS = '3 días hábiles',
	FIVE_DAYS = '5 días hábiles'
}

export enum ReportName {
	LABELS = 'guías',
	OVERWEIGHTS = 'sobrepesos',
	OVERWEIGHTS_SUPPLIER = 'sobrepesos_proveedor',
	INSURANCES = 'seguros'
}

export enum ReportSubType {
	CURRENT_MONTH = 'mes actual',
	PREVIOUS_MONTH = 'mes anterior',
	WEEK = 'semana',
	BIWEEK = 'quincena'
}

export enum ReportDateMonthly {
	DAY_1 = 'día 1 del mes',
	DAY_6 = 'día 6 del mes',
	DAY_12 = 'día 12 del mes',
	DAY_16 = 'día 16 del mes',
	DAY_22 = 'día 22 del mes',
	DAY_26 = 'día 26 del mes',
	DAY_31 = 'último de mes'
}

export enum ReportDateBiweekly {
	FIRST_WEEK = '1/16'
}

export enum ReportDateWeekly {
	MONDAY = 'lunes',
	TUESDAY = 'martes',
	WEDNESDAY = 'miércoles',
	THURSDAY = 'jueves',
	FRIDAY = 'viernes',
	SATURDAY = 'sábado',
	SUNDAY = 'domingo'
}

export enum PreInvoiceDays {
	INMEDIATE = 'Inmediato',
	ONE_DAY = '1 día hábil',
	TWO_DAYS = '2 días hábiles',
	THREE_DAYS = '3 días hábiles',
	PURCHASE_ORDER = 'Orden de compra'
}

export enum ReportFileType {
	SAME_FILE = 'Mismo archivo',
	SEPARATE_FILES = 'Archivos separados'
}

export enum ReportNumber {
	ONE = '1',
	TWO = '2',
	THREE = '3'
}

// -----

export enum CustomerDispersionType {
	GLOBAL = 'global',
	RESTRICTED = 'restricted'
}

export type CustomerService = {
	id: number;
	enabled: boolean;
	discount?: number;
	price_list?: number;
	carrier: string;
};

export type CustomerDetails = {
	id: number;
	name: string;
	last_name: string;
	phone_number: string;
	email: string;
	business_name: string;
	company_name?: string;
	webpage: string;
	company_type: string;
	tax_id?: string;
	users: UserDetails[];
	services: CustomerService[];
	billing: BillingSchema;
	customer_modality?: CustomerModality;
	carriers: CustomerCarriersListType;
	dispersion_type?: CustomerDispersionType;
	modality: string;
};

type MinimalAddress = {
	city: string;
	neighborhood: string;
	number: string;
	state: string;
	street: string;
	suite_number: string;
	zip_code: string;
};

export enum PaymentConditions {
	CASH = 'Contado',
	SEVEN_DAYS = '7 días naturales',
	FIFTEEN_DAYS = '15 días naturales',
	TWENTYTWO_DAYS = '22 días naturales',
	THIRTY_DAYS = '30 días naturales',
	SIXTY_DAYS = '60 días naturales'
}

export enum BillingSchema {
	IMMEDIATE = 'inmediata',
	REPORTS = 'reportes'
}

export enum CustomerReportsReview {
	PLATFORM = 'Revisión Plataforma',
	EXCEL = 'Adjuntar Excel'
}

export type CustomerResponse = {
	seller: SellerResponse;
	business_address: MinimalAddress;
	business_name: string;
	cfdi_description: string;
	company_name: string;
	company_type: string;
	conditions: PaymentConditions;
	contact: {
		id: number;
		role: string;
		email: string;
		name: string;
		phone_number: number;
	};
	customID: string;
	description_of_operation: string | null;
	fiscal_address: MinimalAddress;
	id: number;
	modality: string;
	payment_method: string;
	payment_type: string;
	phone_number: string;
	schema: string;
	services: {
		carrier: string;
		discount: number;
		enabled: boolean;
		id: number;
		min_stock: number;
		mode: string;
		price_list: number;
		service: string;
		irregular_delivery_price?: number;
		reissue_price?: number;
		insurance_percentage?: number;
	}[];
	set_of_operations: string;
	tax_id: string;
	tokens: string[];
	reports: ReportItem[];
	webpage: string;
	status: 'ACTIVE' | 'LOCKED';
	billing: BillingSchema;
	customer_modality?: CustomerModality;
	review_reports?: CustomerReportsReview;
};

export type GetColumnsProps = {
	onEdit?: (id: number) => any;
	onDelete?: (id: number) => any;
};

export type CustomerDocumentType = {
	id: number;
	status: string;
	description: string;
	created_date: string;
	last_update: string;
	comment?: string;
	last_name?: string;
	second_last_name?: string;
	url: string;
	document_type?: DocumentNameType;
	file_type?: string;
	file_name?: string;
	address?: string;
	neighborhood?: string;
	city?: string;
	business_name?: string;
	company_name?: string;
	tax_id?: string;
	number?: string;
	suite_number?: string;
	state?: string;
	street?: string;
	admin_name?: string;
	denomination?: string;
	date?: string;
	public_deed?: string;
	notary_number?: string;
	public_notary?: string;
};

export enum ValidationStatus {
	REGISTER = 'registro',
	PROSPECTING = 'prospección',
	DOCUMENTATION = 'documentación',
	VALIDATED = 'validado',
	VALIDATED_BUT_PENDING_OF_RATES = 'validado_pero_pendiente_tarifas'
}

export enum Entity {
	PHYSICAL = 'Persona física',
	MORAL = 'Persona moral'
}

export enum DocumentNameType {
	INE = 'INE',
	TAX = 'Constancia de situación fiscal',
	PROOF_ADDRESS = 'Comprobante de domicilio',
	CONSTITUTIVE_ACT = 'Acta constitutiva',
	ASSEMBLY_ACT = 'Acta de asamblea'
}

export type DocumentResponseType = {
	prospect_name: string;
	prospect_type: Entity;
	documents: {
		ine: {
			status: string;
			url: string;
			comment: string;
		};
		tax: {
			status: string;
			url: string;
		};
		proof_address: {
			status: string;
			url: string;
		};
		constitutive_act: {
			type: string;
			status: string;
			url: string;
		};
		assembly_act: {
			status: string;
			url: string;
		};
	};
};

export type DocumentRequestType = {
	files: File;
	file_type: string;
	document: string;
};

export type ProspectDocumentsResponseType = {
	ine: {
		names: string;
		last_name: string;
		second_last_name: string;
		zip_code: string;
		neighborhood: string;
		validity: string;
		address: string;
		city: string;
		status: string;
		url: string;
		document_type: string;
		comment: string;
	} | null;
	tax: {
		business_name: string;
		company_name: string;
		number: string;
		suite_number: string;
		street: string;
		zip_code: string;
		neighborhood: string;
		date_of_issue: string;
		city: string;
		state: string;
		status: string;
		url: string;
		document_type: string;
		comment: string;
	} | null;
	proof: {
		number: string;
		suite_number: string;
		street: string;
		zip_code: string;
		neighborhood: string;
		date_of_issue: string;
		city: string;
		state: string;
		status: string;
		url: string;
		document_type: string;
		comment: string;
	} | null;
	constitutive_act: {
		business_name: string;
		denomination: string;
		admin_name: string;
		date: string;
		public_deed: string;
		notary_number: string;
		public_notary: string;
		city: string;
		state: string;
		status: string;
		url: string;
		document_type: string;
		comment: string;
	} | null;
};

export type ProspectResponseType = {
	id: number;
	prospect_id: string;
	name: string;
	// email: string;
	contact: {
		id: number;
		role: string;
		email: string;
		name: string;
		phone_number: number;
	};
	webpage: string | null;
	// status: 'Registro' | 'Prospección' | 'Documentación' | 'Validado';
	validation_status: ValidationStatus;
	last_name?: string;
	business_name?: string;
	business_type?: string;
	set_of_operations?: string;
	channel?: string;
	agents?: number[];
	business_address?: MinDataAddress;
	schema: string;
	modality: string;
	services: {
		carrier: string;
		discount: number;
		enabled: boolean;
		id: number;
		min_stock: number;
		mode: string;
		price_list: number;
		service: string;
		irregular_delivery_price?: number;
		reissue_price?: number;
		insurance_percentage?: number;
	}[];
	cfdi_description: string;
	company_name: string;
	company_type: string;
	conditions: string;
	customID: string;
	description_of_operation: string | null;
	payment_method: string;
	payment_type: string;
	phone_number: string;
	tax_id: string;
	tokens: string[];
	reports: ReportItem[];
	documents: ProspectDocumentsResponseType;
};

export type ProspectRequestType = {
	id: number;
	name: string;
	last_name?: string;
	email: string;
	webpage: string | null;
	// status: 'Registro' | 'Prospección' | 'Documentación' | 'Validado';
	validation_status: ValidationStatus;
	business_name?: string;
	company_name: string;
	company_type: string;
	business_type?: Entity; // TODO:
	set_of_operations?: string;
	channel?: string;
	seller_id: number;

	// agents?: number[];
	// business_address?: MinDataAddress;
	// schema: string;
	// modality: string;
	// services: {
	// 	carrier: string;
	// 	discount: number;
	// 	enabled: boolean;
	// 	id: number;
	// 	min_stock: number;
	// 	mode: string;
	// 	price_list: number;
	// 	service: string;
	// 	irregular_delivery_price?: number;
	// 	reissue_price?: number;
	// 	insurance_percentage?: number;
	// }[];
	// cfdi_description: string;
	// company_type: string;
	// conditions: string;
	// customID: string;
	// description_of_operation: string | null;
	// payment_method: string;
	// payment_type: string;
	// phone_number: string;
	// tax_id: string;
	// tokens: string[];
};

// DISPERSION

export type DispersionTableResponse = {
	assigned_quantity: number;
	consumed_quantity: number;
	created_by: number;
	id: number;
	document: { invoice_id: string; document_date: string; carrier: string };
	invoice_id: number;
	not_assigned_quantity: number;
	remaining_quantity: number;
	service: { id: number; name: string };
	total_quantity: number;
	current_quantity?: number;
	original_quantity?: number;
};

export type SingleDispersionResponse = {
	assigned_quantity: number;
	consumed_quantity: number;
	created_by: number;
	dispersions: {
		id: number;
		name: string;
		consumed_quantity: number;
		current_quantity: number;
		original_quantity: number;
	}[];
	document: { invoice_id: string; document_date: string };
	id: number;
	invoice_id: number;
	not_assigned_quantity: number;
	remaining_quantity: number;
	service: { id: number; name: string; carrier: string };
	total_quantity: number;
	updated_at: number;
};

export type DispersionService = {
	id: number;
	original_quantity: number;
	price: number;
	name: string;
	quantity?: number;
};

export type DispersionsResumeItem = {
	service: string;
	remaining_quantity: number;
	consumed_quantity: number;
	id: number;
	purchased: number;
	replacements: number;
	expired_quantity?: number;
	to_assign?: number;
	to_balance?: number;
};

export type AvailableService = {
	carrier: string;
	id: number;
	mode: string;
	name: string;
	service?: string;
};

export type ResponsePurchase = {
	carrier_id: number;
	carrier: string;
	business_id: number;
	payment_method: string;
	payment_type: string;
	cfdi_use: string;
	customer_id: number;
	order: string;
	services: Array<{
		id: number;
		description: string;
		quantity: number;
		price: number;
	}>;
};

export type RateRequest = {
	zip_origin?: string;
	zip_destination?: string;
	coordinates_destination?: string | { lat: number; lng: number };
	coordinates_origin?: string | { lat: number; lng: number };
	neighborhood_origin?: string;
	neighborhood_destination?: string;
	state_destination?: string;
	city_destination?: string;
	user_id?: number;
	packages: Package[];
};

export enum DispersionPurchaseType {
	DEBT = 'debt',
	BUYS = 'buys'
}

export type ClientBalanceType = {
	id: number;
	amount: number;
	consumed: number;
	remaining: number;
	customerId: number;
	to_assign: number;
};

export enum Channel {
	AMAZON = 'Amazon',
	ML = 'Mercado Libre',
	IG = 'Instagram',
	FB = 'Facebook',
	ECOMMMERCE = 'e-commerce',
	OTHER = 'Otro'
}

export type CarrierList = {
	label: string;
	value: string;
}[];
